<template>
  <div class="column is-half">
    <div class="question-title">
      <span v-if="showQuestionCode">{{ question.code }}: </span>
    </div>
    <div class="card">
      <div class="card-content" v-html="question.question[lang]"></div>
    </div>
  </div>
</template>

<script>
import { QuestionMixin } from '@/mixins/question'

export default {
  name: 'TextDisplay',
  mixins: [QuestionMixin],
}
</script>
